import styled from "styled-components";
import mainBcg from "../../assets/header-container/mainBcg.svg";

export const PageHeaderContainer = styled.div`
  background: transparent
    radial-gradient(closest-side at 50% 40%, #6a42d1 -20%, #23222200 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
  // @media only screen and (max-width: 980px) {
  //   height: 800px;
  //   background: transparent url(${mainBcg}) 50% 50% no-repeat padding-box;
  //   background-size: cover;
  //   opacity: 1;
  // }
    }
`;
