import { VegvizerLogo, WhiteLine } from "../../assets";
import {
  FooterContainer,
  FooterItem,
  FooterSecLine,
  WhiteContent,
  GreenContent,
  LogoItem,
} from "./footerStyles";
import { CALL_TXT, PHONE_NUM, FIND_TXT, ADDRESS } from "../../constants/texts";
import { PopupWidget, InlineWidget, PopupModal } from "react-calendly";
import { useEffect, useState } from "react";

export default function Footer() {
  const [isOpen, setIsOpen] = useState(false);
  // let HTML_ELM = document.getElementById("root");

  return (
    <FooterContainer>
      <FooterItem>
        <WhiteContent>{CALL_TXT}</WhiteContent>
        <GreenContent
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Click here
        </GreenContent>
        {document && (
          <PopupModal
            onModalClose={() => {
              setIsOpen(false);
            }}
            open={isOpen}
            rootElement={document.getElementById("root") as HTMLElement}
            url="https://calendly.com/tal-vegvizer/30min"
          />
        )}
        {/* <PopupWidget
          
          text="Click here to schedule!"
          textColor="#ffffff"
          color="#00a2ff"
        /> */}
      </FooterItem>
      <FooterItem>
        <WhiteLine height="100%" preserveAspectRatio="none" />
      </FooterItem>
      <FooterItem>
        <WhiteContent>{FIND_TXT}</WhiteContent>
        <GreenContent>{ADDRESS}</GreenContent>
      </FooterItem>
      <FooterSecLine>
        <WhiteLine height="100%" preserveAspectRatio="none" />
      </FooterSecLine>
      <LogoItem>
        <VegvizerLogo />
      </LogoItem>
    </FooterContainer>
  );
}
