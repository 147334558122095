import * as assets from "../assets";

//TECHNOLOGIES
import { Technologies } from "./technologies";
import { Categories } from "./technologies";
const CategoryTitles = Object.values(Categories);
const SuffeledCategoryTitles = CategoryTitles.sort(() => 0.5 - Math.random());
export const TECHNOLOGYS_GROUPS: any = SuffeledCategoryTitles.map(
  (title, idx) => {
    return { id: idx, title, items: [] };
  }
);
export const updateTechnologiesGroups = () => {
  TECHNOLOGYS_GROUPS.forEach((group: any) => {
    const technology = Technologies.filter(
      (tech) => tech.category === group.title
    );
    group.items.push(...technology);
  });
};
updateTechnologiesGroups();

//PRODUCTS
export const PRODUCTS = [
  {
    id: 0,
    icon: assets.devopsIcon1,
    title: "DevOps",
    subTitle:
      "Lorem ipsum dolor sit ametos, consectetuer adipiscing ela sed dia monor. ",
    content:
      "nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
    logo: assets.terragramLogo,
  },
  {
    id: 1,
    icon: assets.finopsIcon,
    title: "Finops",
    subTitle:
      "Lorem ipsum dolor sit ametos, consectetuer adipiscing ela sed dia monor. ",
    content:
      "nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
    logo: assets.finopsysLogo,
  },
];

//SERVICES
export const SERVICES = [
  {
    id: 0,
    img: assets.devopsIcon1,
    title: "DevOps",
    subTitle: "Accelerate Your Innovation",
    content:
      "Experience the future of software development with our DevOps expertise. We automate and streamline your processes, enabling faster, more reliable releases that keep you ahead of the competition.",
  },
  {
    id: 1,
    img: assets.devopsIcon2,
    title: "Cloud Solutions",
    subTitle: "Elevate Your Business to New Heights",
    content:
      "Unlock the limitless potential of the cloud with our expert solutions. We guide you through a seamless migration, ensuring your infrastructure is not just moved but optimized for peak performance and security.",
  },
  {
    id: 2,
    img: assets.ai,
    title: "AI Innovation",
    subTitle:
      "Our AI specialists at Vegvizer are at the forefront of artificial intelligence innovation.",
    content:
      "By implementing cutting-edge AI technologies, they create intelligent solutions that enhance efficiency, drive automation, and enable smarter decision-making for our clients",
  },
  {
    id: 3,
    img: assets.data,
    title: "Big Data",
    subTitle:
      "At Vegvizer, our data experts harness the power of data to drive informed decision-making and business insights.",
    content:
      "By leveraging advanced analytics and data management techniques, we help clients unlock the full potential of their data assets",
  },
  {
    id: 4,
    img: assets.developmentIcon,
    title: "Digital Development",
    subTitle:
      "Software development is the process of designing, creating, testing, and maintaining software applications and systems. ",
    content:
      "From concept to code, our digital development team crafts custom software and applications that are as unique as your business. We transform ideas into powerful digital experiences.",
  },
];

//CLIENTS
export const CLIENTS = [
  {
    id: 0,
    isOn: true,
    logo: assets.clalit,
    review:
      "0 Tal and his team are true professio-nals, nonummy nibh euismod tindunt ut laoreet dolore magna aliquam erat. Ut wisi enim ad minim viam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
    writtenBy: "0 Moran L, CMO",
  },
  {
    id: 1,
    isOn: false,
    logo: assets.clalit,
    review:
      "1 Yogi and his team are true professio-nals, nonummy nibh euismod tindunt ut laoreet dolore magna aliquam erat. Ut wisi enim ad minim viam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
    writtenBy: "1 Dana L, CMO",
  },
  {
    id: 2,
    isOn: false,
    logo: assets.clalit,
    review:
      "2 Tomer and his team are true professio-nals, nonummy nibh euismod tindunt ut laoreet dolore magna aliquam erat. Ut wisi enim ad minim viam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
    writtenBy: "2 Vig L, CMO",
  },
  {
    id: 3,
    isOn: false,
    logo: assets.clalit,
    review:
      "3 Tomer and his team are true professio-nals, nonummy nibh euismod tindunt ut laoreet dolore magna aliquam erat. Ut wisi enim ad minim viam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
    writtenBy: "3 Vig L, CMO",
  },
  {
    id: 4,
    isOn: false,
    logo: assets.clalit,
    review:
      "4 Tomer and his team are true professio-nals, nonummy nibh euismod tindunt ut laoreet dolore magna aliquam erat. Ut wisi enim ad minim viam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
    writtenBy: "4 Vig L, CMO",
  },
  {
    id: 5,
    isOn: false,
    logo: assets.clalit,
    review:
      "5 Tomer and his team are true professio-nals, nonummy nibh euismod tindunt ut laoreet dolore magna aliquam erat. Ut wisi enim ad minim viam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.",
    writtenBy: "5 Vig L, CMO",
  },
];

//TEAM
export const MEMBERS = [
  {
    img: assets.tal,
    in: "https://www.linkedin.com/in/tal-vegvizer-a7528545/",
    title: "Tal Vegvizer, CEO & Founder",
    description:
      "Visionary leader and co-founder of Vegvizer, Tal drives our mission to deliver innovative technology solutions. With a strategic mind and a passion for digital transformation, Tal leads our team to new heights",
  },
  {
    img: assets.yarden,
    in: "https://www.linkedin.com/in/yarden-fayer-853b74144/",
    title: "Yarden Fayer, FinOps",
    description:
      "Yarden ensures our clients get the most value from their cloud investments. As a FinOps Engineer, they specialize in financial operations within cloud environments, optimizing costs and enhancing efficiency",
  },
  {
    img: assets.afik,
    in: "https://www.linkedin.com/in/afik-lifshitz/",
    title: "Afik Lifshitz, Cloud & DevOps Engineer",
    description:
      "Afik bridges the gap between development and operations, bringing automation and streamlined processes to our projects. Their expertise in DevOps accelerates our software delivery, ensuring reliability and speed",
  },
  {
    img: assets.tomer,
    in: "https://www.linkedin.com/in/tomer-raz",
    title: "Tomer Raz, DevOps & Full Stack Engineer",
    description:
      "Tomer manages and optimizes cloud infrastructure, ensuring seamless performance and security. Their skills in CloudOps keep our clients' systems running smoothly, no matter the scale.",
  },
  {
    img: assets.meir,
    in: "https://www.linkedin.com/in/meir-lixen/",
    title: "Meir Lixenberg, Senior Full Stack Engineer",
    description:
      "Meir brings a versatile skill set to the team, handling both front-end and back-end development with ease. Their ability to create cohesive and dynamic digital solutions makes them an invaluable asset to our projects",
  },
  {
    img: assets.lior,
    in: "https://www.linkedin.com/in/lior-ariel/",
    title: "Lior Ariel, Cloud & DevOps Engineer",
    description:
      "Lior is our versatile expert who seamlessly bridges the realms of cloud computing and DevOps. With a deep understanding of both disciplines, [Name] orchestrates the deployment, management, and optimization of cloud infrastructure while implementing DevOps best practices to enhance agility, efficiency, and reliability. Lior plays a pivotal role in driving digital transformation, ensuring that our clients leverage the full potential of cloud technology with the agility of DevOps methodologies",
  },
  {
    img: assets.david,
    in: "https://www.linkedin.com/in/davgrits/",
    title: "David Grits, DevOps & Automation Engineer",
    description:
      "David streamlining processes and ensuring reliability. They leverage automation tools and DevOps methodologies to orchestrate seamless software delivery pipelines, empowering clients with agile and scalable solutions.",
  },
  {
    img: assets.or,
    in: "https://www.linkedin.com/in/or-allony/",
    title: "Or Allony, Cloud & DevOps Engineer",
    description:
      "Or orchestrates the deployment, management, and optimization of cloud infrastructure while implementing DevOps best practices to enhance agility, efficiency, and reliability.",
  },
  {
    img: assets.yanir,
    in: "https://www.linkedin.com/in/yanir-winnik-84a7501bb/",
    title: "Yanir Winnik, Cloud & DevOps Engineer",
    description:
      "Yanir wears multiple hats at Vegvizer. They excel in crafting scalable cloud solutions while also spearheading our DevOps initiatives. combines their in-depth knowledge of cloud technologies with DevOps principles to deliver end-to-end solutions that optimize performance, enhance reliability, and enable rapid innovation for our clients",
  },
  {
    img: assets.yogev,
    in: "https://www.linkedin.com/in/yogev-ohayon-33b66059/",
    title: "Yogev Ohayon, Full stack developer",
    description:
      "Yogev brings a versatile skill set to the team, handling both front-end and back-end development with ease. Their ability to create cohesive and dynamic digital solutions makes them an invaluable asset to our projects",
  },
];
