import { useState } from "react";
import { VegvizerLogo, SidebarLogo } from "../../assets";
import { HeaderContainer, SidebarIconWrap } from "./headerStyles";
import Sidebar from "../sidebar/sidebar";

const Header = () => {
  const [isSidebarShown, setIsSidebarShown] = useState(false);
  const toggleSidebar = () => setIsSidebarShown(!isSidebarShown);
  return (
    <div>
      <HeaderContainer>
        <VegvizerLogo />
        <SidebarIconWrap>
          <SidebarLogo onClick={toggleSidebar} />
        </SidebarIconWrap>
      </HeaderContainer>
      <Sidebar isSidebarShown={isSidebarShown} toggleSidebar={toggleSidebar} />
    </div>
  );
};

export default Header;
