//HEADER
export const GREET_TXT = "Hey there, Stranger";
export const INTRO_TXT1 = "We are Vegvizer, and we ";
export const INTRO_TXT2 = "navigate the complexities of technology to find clear,";
export const INTRO_TXT3 = "actionable solutions for your business.";
export const EXPLORE_BTN_TXT = "Explore our services";
//PRODUCTS
export const EXPLORE_TXT = "Coming soon...";
//SERVICES
export const TALK_BTN_TXT = "Talk to us, we are cute";
//CLIENTS
export const CLIENTS_TITLE = "Our clients are cute too btw";
//TECHNOLOGIES
export const TECH_BTN_TXT = "Even more technologies";
export const TECH_TITLE = "We use cutting-edge technologies";
//TEAM
export const TEAM_TITLE = "Say hello to our dream team";
export const TEAM_BTN_TXT = "Meet them all";
//CONTACT_FORM
export const FORM_TITLE_TXT = "Wanna Chat?";
export const FORM_SUB_TXT = "Don't hesitate to be in touch.";
export const FORM_BTN_TXT = "How can we help?";
//FOOTER
export const CALL_TXT = "BOOK A CALL";
export const PHONE_NUM = "+972 556644024";
export const FIND_TXT = "FIND US";
export const ADDRESS = "IL, Ramat Gan, Aba Hill st. 21";
