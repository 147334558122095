import { ServiceItemContainer } from "./serviceStyles";
import {
  ItemTitle,
  ItemSubTitle,
  ItemContent,
  ItemImg,
  LineContainer,
} from "../products/productStyles";
import { WhiteLine } from "../../../assets";

export default function ServiceItem({ service }: any) {
  return (
    <ServiceItemContainer>
      <ItemImg>
        <img src={service.img} alt="" style={{ maxWidth:"110px", maxHeight: "100px"}} />
      </ItemImg>
      <ItemTitle>{service.title}</ItemTitle>
      <ItemSubTitle>{service.subTitle}</ItemSubTitle>
      <ItemContent>{service.content}</ItemContent>
      <LineContainer>
        <WhiteLine height="100%" preserveAspectRatio="none" />
      </LineContainer>
    </ServiceItemContainer>
  );
}
